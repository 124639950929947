import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import ajax from '../../ajax'
import functions from '../../functions'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import RightSideBar from '../../Components/Common/RightSideBar'
import Footer from '../../Components/Common/Footer'
import CustomerSummary from '../../Components/User/CustomerSummary'
import InitialAvatar from '../../Components/Common/InitialAvatar'
import CustomerProfileData from '../../Components/User/CustomerProfileData'
import CustomerWallet from '../../Components/Wallet/CustomerWallet'
import CustomerLoans from '../../Components/Loan/CustomerLoans'
import WarnDelete from '../../Components/WarnDelete'
import $ from 'jquery';
import Toast from '../../Components/Common/Toast'
import PopChangeLimit from '../../Components/Loan/PopChangeLimit'
import UserControls from '../../Components/User/UserControls'
import PopUserLocation from '../../Components/User/PopUserLocation'
import PopVerifyCustomer from '../../Components/User/PopVerifyCustomer'
import PopImageViewer from '../../Components/Common/PopImageViewer'

class CustomerProfile extends React.Component {

    state={
        userId:functions.sessionGuard(),
		customerInfo:false,
        customerId:false,
        walletBalance:'...',
		loanBalance:'...',
		walletStatement:false,
		loanHistory:false,
		filter:"ALL",
		metaData:false,
		page:1,
		menuOptions:'',
		currentOption:'PROFILE',
		toast:false,
		warnAction:false,
		changeLimit:false,
		securityBalance:'...',
		locateUser:false,
		verification:false,
		imageViewer:false,
		loading:false
      }

      componentDidMount()
      {
		if(!this.props.location.state)
		{
			this.props.history.push('/customers/list_customers');
		}else{
			this.setState({
				customerId:this.props.location.state.customerId
			}, ()=>{
				this.getCustomerProfile();
				this.getWalletStatement();
				this.listLoans();
			})


			this.setState({
				menuOptions: {
					"profile": () => this.onClickProfile(),
					"history": () => this.onClickLoanHistory(),
					"transactions": () => this.onClickWallet(),
					"pinReset": () => this.onClickResetPIN(),
					"changeLimit": () => this.onClickChangeLimit(),
					"userLocation": () => this.onClickLocateUser()

				}
			})

		

		}

        
        this.timerWalletBalance = setTimeout(this.func=async()=> {
            await this.getWalletBalance();
            this.timerWalletBalance = setTimeout(this.func, 2000); // (*)
          }, 2000);

          this.timerLoanBalance = setTimeout(this.func2=async()=> {
            await this.getLoanBalance();
            this.timerLoanBalance = setTimeout(this.func2, 2000); // (*)
		  }, 2000);
		  
		  this.timerSecurityBalance= setTimeout(this.func3=async()=> {
			await this.getSecurityBalance();
			this.timerSecurityBalance = setTimeout(this.func2, 2000); // (*)
		  }, 2000);
		
	  }


	 

       componentDidUpdate() {
       
		if(this.props.location.state)
        {
         
          if(this.props.location.state.customerId!==this.state.customerId)
          {
			this.setState({
				loading:true,
				customerId:false,
				customerInfo:false
			}, ()=>{

				this.setState({
					loading:false,
					customerId:this.props.location.state.customerId
				}, ()=>{

						this.getCustomerProfile();
						this.getWalletStatement();
						this.listLoans();

						this.timerWalletBalance = setTimeout(this.func=async()=> {
							await this.getWalletBalance();
							this.timerWalletBalance = setTimeout(this.func, 2000); // (*)
						}, 2000);
				
						this.timerLoanBalance = setTimeout(this.func2=async()=> {
							await this.getLoanBalance();
							this.timerLoanBalance = setTimeout(this.func2, 2000); // (*)
						}, 2000);

						this.timerSecurityBalance= setTimeout(this.func3=async()=> {
							await this.getSecurityBalance();
							this.timerSecurityBalance = setTimeout(this.func2, 2000); // (*)
						}, 2000);


					})

				})
          }
        }
           
      }

      componentWillUnmount()
      {
          clearTimeout(this.timerWalletBalance);
		  clearTimeout(this.timerLoanBalance);
		  clearTimeout(this.timerSecurityBalance);

      }

      getCustomerProfile=async()=>{
          const server_response=await ajax.getCustomerProfile(this.state.customerId);
          if(server_response.status==="OK")
          {
             this.setState({
                customerInfo:server_response.details
             })

          }else{
            this.setState({
                customerInfo:"404"
            })
          }
      }


      getWalletBalance=async()=>{
        const server_response=await ajax.getWalletBalance(this.state.customerId);
        if(server_response.status==="OK")
        {
           this.setState({
                walletBalance:server_response.details.amount_c
           })

        }
	}
	

	getSecurityBalance=async()=>{
        const server_response=await ajax.getUserSecurityBalance(this.state.customerId);
        if(server_response.status==="OK")
        {
           this.setState({
                securityBalance:server_response.details.amount_c
           })

        }
    }


    getLoanBalance=async()=>{
        const server_response=await ajax.getLoanBalance(this.state.customerId);
        if(server_response.status==="OK")
        {
           this.setState({
                loanBalance:server_response.details.amount_c
           })

        }
    }
      
	getWalletStatement=async()=>{
		const server_response=await ajax.getWalletStatement(this.state.customerId);
		if(server_response.status==="OK")
		{
		   this.setState({
			  walletStatement:server_response.details['records']
		   })

		}else{
		  this.setState({
			walletStatement:"404"
		  })
		}
	}


	listLoans=async()=>{
		const server_response=await ajax.getLoans(this.state.filter, this.state.page, this.state.customerId);
		if(server_response.status==="OK")
		{
		   this.setState({
			loanHistory:server_response.details.list,
			  metaData:server_response.details.meta
		   })

		}else{
		  this.setState({
			loanHistory:"404"
		  })
		}
	}
	  
	onClickAgentProfile=(id)=>{
        this.props.history.push({
          pathname:'/agent-profile',
          state:{agentId:id}
        })
      
	  }
	  
	  onClickResetPIN=()=>{
		this.setState({
			warnAction:false
		}, ()=>{

				this.setState({
					warnAction:<WarnDelete
					title="Reset User PIN?"
					description="You are about to reset this user's transaction PIN! Make sure you inform this user immediately to avoid security bleach."
					buttonLabel="Reset PIN"
					onConfirm={()=>this.onResetPIN()}/>
				}, ()=>{
					$("#modal_delete").modal('show');
				})

		})
	  }

	  resetToast=()=>{
		  this.setState({
			  toast:false
		  })
	  }

	  onResetPIN=async()=>{
		
		const server_response=await ajax.resetTransactionKey(this.state.customerId);
		$("#modal_delete").modal('hide');
		if(server_response.status==="OK")
		{
		   this.setState({
			toast:<Toast 
				message={server_response.details[0]} 
				onClose={()=>this.resetToast()}/>
		   })

		}else{
			this.setState({
				toast:<Toast 
						message={server_response.details[0]} 
						type={3} onClose={()=>this.resetToast()}/>
			})
		}
	  }


	  
	  onClickProfile=()=>{
		this.setState({
			currentOption:"PROFILE"
		})
  		}

		onClickLoanHistory=()=>{
			this.setState({
				currentOption:"LOANS"
			})
		}

		onClickWallet=()=>{
			this.setState({
				currentOption:"WALLET"
			})
		}

		onClickChangeLimit=()=>{
			this.setState({
				changeLimit:false
			}, ()=>{
				this.setState({
					changeLimit:<PopChangeLimit onConfirm={this.onChangeLimit}/>
				}, ()=>{
					$("#modal_change_limit").modal('show');
				})
			})
			
		}
		
		onChangeLimit=async(amount)=>{


			const server_response=await ajax.updateCustomerLimit(this.state.customerId, amount);
			$("#modal_change_limit").modal('hide');
			if(server_response.status==="OK")
			{
			   this.setState({
				toast:<Toast 
					message={server_response.details[0]} 
					onClose={()=>this.resetToast()}/>
			   })
	
			}else{
				this.setState({
					toast:<Toast 
							message={server_response.details[0]} 
							type={3} onClose={()=>this.resetToast()}/>
				}, ()=>{


					this.setState({
						customerInfo:false
					}, ()=>{
		
						this.getCustomerProfile();
						this.getWalletStatement();
						this.listLoans();
					})

				})


				
			}

				
		}

		onClickLocateUser=()=>{
			this.setState({
				locateUser:false
			}, ()=>{
				this.setState({
					locateUser:<PopUserLocation userId={this.state.customerId}/>
				}, ()=>{
					$("#pop-user-location").modal('show');
				})
			})
			
		}



		onClickVerify=(userInfo)=>{
			this.setState({
			  verification:false
			}, ()=>{
		
			  this.setState({
				verification:<PopVerifyCustomer data={userInfo} onUpdate={this.onUpdateRecord}/>
			  }, ()=>functions.openPopup('modal_verify_customer'))
			})
		   
		  }
		
		  onUpdateRecord=()=>{
			  this.setState({
				currentOption:'LOADING..'
			  }, ()=>this.setState({currentOption:'PROFILE'}))
			
		  }



		  onClickImage=(url, title)=>{
			this.setState({
			  imageViewer:false
			}, ()=>{
			  this.setState({
				imageViewer:<PopImageViewer url={url} title={title}/>
			  }, ()=>functions.openPopup('modal_image_viewer'))
			})
		  }
	  


    
    render(){
	   //console.log(this.state.customerId)
		const customer=this.state.customerInfo
        //console.log(this.props.location.state.customerId)
          return (
            <div className="page">
			{!this.state.loading && <>
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar back={()=>this.props.history.goBack()}/>
                <div className="container-fluid">
                <PageHeader 
                     title="Customer Profile"
					 previousPageTitle="Customers"
					 back={"/customer-records"}
					 options={this.state.menuOptions}
                  />
				   {this.state.toast && this.state.toast}
				  {this.state.warnAction && this.state.warnAction}
				  {this.state.changeLimit && this.state.changeLimit}
				  {this.state.locateUser}
				  {this.state.verification}
				  {this.state.imageViewer}
                  
						{!customer && <ActivityLoader/>}
							



				{customer && customer!=="404" && 
				   <div className="row">

                    <div className="col-lg-4 col-md-12">
							<div className="card custom-card">
								<div className="card-body text-center">
									<div className="main-profile-overview widget-user-image text-center">
										<div className="main-img-user">

										{this.state.customerInfo.user.photo_name==="avatar.png" &&
										 <InitialAvatar value={this.state.customerInfo.user.initials}/>}
											{this.state.customerInfo.user.photo_name!=="avatar.png" &&
											<img 
												src={this.state.customerInfo.user.photo}
												className="pointer"
												onClick={()=>this.onClickImage(this.state.customerInfo.user.photo, 'Customer Passport Photo')}
											/>}
										</div>
									</div>
									<div className="item-user pro-user">
										<h4 className="pro-user-username text-dark mt-2 mb-0">
											{this.state.customerInfo.user.first_name + ' ' + this.state.customerInfo.user.last_name}
										</h4>
										<p className="pro-user-desc text-muted mb-1">Customer</p>
										<p className="user-info-rating">
											<a><i className="fa fa-star text-warning"> </i></a>
											<a><i className="fa fa-star text-warning"> </i></a>
											<a><i className="fa fa-star text-warning"> </i></a>
											<a><i className="fa fa-star text-warning"> </i></a>
											<a><i className="far fa-star text-warning"> </i></a>
										</p>
									
										{/* <Link to={{
                                                pathname:'/customer-profile/edit',
                                                state:{customerId:this.state.customerInfo.user.user_id}
                                            }}     
											className="btn ripple btn-primary btn-sm">
											<i className="far fa-edit mr-1"></i>Edit
										</Link> */}

										{this.state.customerInfo.user.verified*1 == 1 && 
											<button
												onClick={()=>this.onClickVerify(this.state.customerInfo.user)}
												className="btn ripple btn-success btn-sm">
												<i className="fa fa-check mr-1"></i>Verify
											</button>}

											{this.state.customerInfo.user.verified*1 == 0 && 
											<button
												onClick={()=>this.onClickVerify(this.state.customerInfo.user)}
												className="btn ripple btn-dark btn-sm">
												<i className="fa fa-check mr-1"></i>Verify
											</button>}										


										&nbsp;<button className="btn ripple btn-secondary btn-sm"><i className="fas fa-stop-circle"></i>Block</button>
								
									
									</div>
								</div>
								<div className="card-footer p-0">
									<div className="row text-center">
										<div className="col-sm-6 border-right">
											<div className="description-block">
												<h5 className="description-header mb-1">
                                                    {this.state.loanBalance}
                                                </h5>
												<span className="text-muted">Loan Balance</span>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="description-block">
												<h5 className="description-header mb-1">
                                                    {this.state.walletBalance}
                                                </h5>
												<span className="text-muted">Wallet Balance</span>
											</div>
										</div>

										<div className="col-sm-6 border-right">
											<div className="description-block">
												<h5 className="description-header mb-1">
                                                    {this.state.securityBalance}
                                                </h5>
												<span className="text-muted">Security Balance</span>
											</div>
										</div>
									</div>
								</div>

							</div>

							<UserControls info={this.state.customerInfo.user}/>
						
						
                        </div>
                  


                        <div className="col-lg-8 col-md-12">
							<CustomerSummary info={this.state.customerInfo}/>
							{this.state.currentOption==="PROFILE" && <CustomerProfileData info={this.state.customerInfo} onClickImage={this.onClickImage}/>}
							{this.state.currentOption==="WALLET" && <CustomerWallet clientId={this.state.customerInfo.user.user_id}/>}
							{this.state.currentOption==="LOANS" && <CustomerLoans clientId={this.state.customerInfo.user.user_id}/>}


						</div>








                    </div>}








                          
                </div>
            </div>
            <RightSideBar/>
            <Footer/>
			</>}
			{this.state.loading && <ActivityLoader/>}
        </div>);  
   
    }
  }
  
  export default CustomerProfile;
  