import React from 'react';
import functions from '../../functions';


class AgentProfileData extends React.Component {
  
  state={
    userId:functions.sessionGuard(),
    agentInfo:this.props.info
  }


    
  render(){
    const documents=this.state.agentInfo.user.documents;

      return (
       
        <div className="card custom-card">
          <div className="">
            <div className="main-content-body main-content-body-contacts">
             
              <div className="main-contact-info-body">
                
                <div className="media-list pb-lg-0">
                  <div className="media">
                    <div className="media-body">
                      <div>
                        <label>Full Name</label> 
                        <span className="tx-medium">{this.state.agentInfo.user.first_name + ' ' + this.state.agentInfo.user.last_name}</span>
                      </div>
                      <div>
                        <label>Gender</label> 
                        <span className="tx-medium">{this.state.agentInfo.user.gender}</span>
                      </div>
                    </div>
                  </div>
                  <div className="media">
                    <div className="media-body">
                      <div>
                        <label>Birthday</label> 
                        <span className="tx-medium">{this.state.agentInfo.user.dob.picker}</span>
                      </div>
                      <div>
                        <label>Phone</label> 
                        <span className="tx-medium">{this.state.agentInfo.user.phone}</span>
                      </div>
                    </div>
                  </div>
                  
                  <div className="media mb-0">
                    <div className="media-body">
                      <div>
                        <label>Email</label> 
                        <span className="tx-medium">{this.state.agentInfo.user.email}</span>
                      </div>
                      <div>
                        <label>Agent</label> 
                        <span className="tx-medium">
                            {this.state.agentInfo.agent_info && this.state.agentInfo.agent_info.first_name + " " + this.state.agentInfo.agent_info.last_name + "(" + this.state.agentInfo.agent_info.phone + ")"}
                            {!this.state.agentInfo.agent_info && "n/a"}
                          </span>
                      </div>
                    </div>
                  </div>

                  <div className="media">
                    <div className="media-body">
                      <div>
                        <label>Current Address</label> 
                        <span className="tx-medium">{this.state.agentInfo.user.address.address}</span>
                      </div>
                    </div>
                  </div>



                  <div className="media">
                    <div className="media-body">
                      <div>
                        <label>Reg. Date</label> 
                        <span className="tx-medium">{this.state.agentInfo.user.reg_date}</span>
                      </div>
                      <div>
                        <label>Acc. Code</label> 
                        <span className="tx-medium">{this.state.agentInfo.user.account_number}</span>
                      </div>
                    </div>
                  </div>


                  
                  <div className="media">
                    <div className="media-body">
                      <div>
                        <label>National ID</label> 
                        <span className="tx-medium">{this.state.agentInfo.user.NIN}</span>
                      </div>
                     
                    </div>
                  </div>


                   
                  <div className="media">
                    <div className="media-body">
                      <div>
                        <label>Current Device Token</label> 
                        <span className="tx-medium">{this.state.agentInfo.user.device}</span>
                      </div>
                     
                    </div>
                  </div>


                </div>

                <div className="main-content-label tx-13 mg-b-20 mt-3">
											Documents
										</div>
										<div className="row">
                      {documents.map((item, key)=>
                      	<div className="col-6 col-md-3" key={key}>
                        <img 
                          alt="Document" 
                          title={item.document_name}
                          className="img-thumbnail" 
                          src={item.file_path}/>
											</div>
                      
                      )}
										</div>





              </div>
            </div>
          </div>
        </div>

      );
    
 
  }
}

export default AgentProfileData