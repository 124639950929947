import React from 'react';
import ajax from '../ajax';
import functions from '../functions';
import ActivityLoader from './Common/ActivityLoader';
import {Line} from 'react-chartjs-2';


class WeeklyTransactionGraph extends React.Component {

    state={
        userId:functions.sessionGuard(),
        chartData:false,
        salesData:false,
        borderColors:['rgba(113, 76, 190, 0.9)', 'rgba(235, 111, 51, 0.8)', 'rgba(21, 197, 95, 0.6)'],
        backgroundColors:['rgba(113, 76, 190, 0.5)', 'rgba(235, 111, 51, 0.8)', 'rgba(21, 197, 95, 0.8)']
    }

    async componentDidMount()
    {
        await this.fetchData();
        this.timerLoan = setTimeout(this.func=async()=> {
            await this.fetchData();
            this.timerLoan = setTimeout(this.func, 2000); // (*)
          }, 2000);
    }

    componentWillUnmount() {
        clearTimeout(this.timerLoan)
    }
    


    fetchData=async()=>
    {
        const server_reponse=await ajax.analyseWeeklyTransactions();
        if(server_reponse.status==="OK")
        {
            this.setState({
                salesData:server_reponse.details
            }, ()=>{

                const days=[this.state.salesData.days[7]];//start with sunday
                for(var i=1; i<7; i++)
                {  
                    days.push(this.state.salesData.days[i]);
                }
                const chartData={labels:days, datasets:[]};
                const deposits=[this.state.salesData.deposits[7]['total']];//start with sunday
                for(var i=1; i<7; i++)
                {
                    deposits.push(this.state.salesData.deposits[i]['total']);
                }
                const withdraws=[this.state.salesData.withdraws[7]['total']];//start with sunday
                for(var i=1; i<7; i++)
                {
                    withdraws.push(this.state.salesData.withdraws[i]['total']);
                }

               
                    chartData.datasets.push({
                        label:"Deposits",
                        data:deposits,
                        fill: true,
                        lineTension: 0.5,
                        borderWidth: 2,
                        backgroundColor:this.state.backgroundColors[0],
                        borderColor:this.state.borderColors[0]
                    })

                    chartData.datasets.push({
                        label:"Withdraws",
                        data:withdraws,
                        fill: true,
                        lineTension: 0.5,
                        borderWidth: 2,
                        backgroundColor:this.state.backgroundColors[1],
                        borderColor:this.state.borderColors[1]
                    })
                
                
                this.setState({chartData:chartData})
            })
        }
    }
  
  
  render(){
      const list=this.state.list;
    
      return (
        <div className="card custom-card">
        <div className="card-body">

        <div className="card-option d-flex">
            <div>
                <h6 className="card-title mb-1">Weekly Transactions</h6>
                <p className="text-muted card-sub-title">Overview of this week's wallet & business float deposits and withdraws</p>
            </div>
            <div className="card-option-title ml-auto">
                {/* <div className="btn-group p-0">
                    <button className="btn btn-light btn-sm" type="button">Month</button>
                    <button className="btn btn-outline-light btn-sm" type="button">Year</button>
                </div> */}
            </div>
        </div>
        <div>


                    {this.state.chartData && this.state.chartData!=="404" && <Line
                        data={this.state.chartData }
                        height={90}
                        options={{
                            title:{
                            display:false,
                            //text:'Graph title',
                            fontSize:20
                            },
                            legend:{
                            display:true,
                            position:'top'
                            }
                            
                        }}
                        />}

                        {!this.state.chartData && <ActivityLoader/>}


        </div>
            

        </div>
    </div>

      )
    
 
  }
}

export default WeeklyTransactionGraph;