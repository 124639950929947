import React from 'react'
import LeftSideBar from '../Components/Common/LeftSideBar'
import TopBar from '../Components/Common/TopBar'
import PageHeader from '../Components/Common/PageHeader'
import ajax from '../ajax'
import functions from '../functions'
import ActivityLoader from '../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import RightSideBar from '../Components/Common/RightSideBar'
import Footer from '../Components/Common/Footer'
import InitialAvatar from '../Components/Common/InitialAvatar'
import Toast from '../Components/Common/Toast'
import PopChangeLimit from '../Components/Loan/PopChangeLimit'
import WarnDelete from '../Components/WarnDelete'
import $ from 'jquery';
import AgentProfileData from '../Components/User/AgentProfileData'
import AgentSummary from '../Components/User/AgentSummary'
import AgentWallet from '../Components/Wallet/AgentWallet'
import AgentLoans from '../Components/Loan/AgentLoans'
import AgentFloat from '../Components/Wallet/AgentFloat'
import UserControls from '../Components/User/UserControls'
import PopUserLocation from '../Components/User/PopUserLocation'

class AgentProfile extends React.Component {

    state={
        userId:functions.sessionGuard(),
		agentInfo:false,
		agentId:false,
		walletStatement:false,
		walletBalance:'...',
		floatBalance:'...',
		loanHistory:false,
		filter:"ALL",
		metaData:false,
		page:1,
		menuOptions:'',
		currentOption:'PROFILE',
		toast:false,
		warnAction:false,
		changeLimit:false,
		accessInfo:functions.readPermissions(),
		locateUser:false,
		loanStatistics:false,
		loading:false
	  }


      componentDidMount()
      {
		if(!this.props.location.state)
		{
			this.props.history.push('/agent-records');
		}else{
			this.setState({
				agentId:this.props.location.state.agentId
			}, ()=>{
				this.getAgentProfile();
				this.getWalletStatement();
				this.listLoans();

				this.timerWalletBalance = setTimeout(this.func1=async()=> {
					await this.getWalletBalance();
					this.timerWalletBalance = setTimeout(this.func1, 2000); // (*)
				  }, 2000);

				  
				this.timerFloatBalance = setTimeout(this.func2=async()=> {
					await this.getFloatBalance();
					this.timerFloatBalance = setTimeout(this.func2, 2000); // (*)
				  }, 2000);


				  this.getLoanStatistics(); 
			})

		}

		this.setState({
			menuOptions: {
				"profile": () => this.onClickProfile(),
				"history": () => this.onClickLoanHistory(),
				"bFloat": () => this.onClickWallet(),
				"cFloat": () => this.onClickFloat(),
				"pinReset": () => this.onClickResetPIN(),
				"changeLimit": () => this.onClickChangeLimit(),
				"userLocation": () => this.onClickLocateUser()

			}
		})

		
	  }


	  


	//   static getDerivedStateFromProps(nextProps, prevState) {
        
    //     return {
	// 		agentId:nextProps.location.state?nextProps.location.state.agentId:0        
	// 	};
    //    }

       componentDidUpdate() {
       
        if(this.props.location.state)
        {
         
          if(this.props.location.state.agentId!==this.state.agentId)
          {
				this.setState({
					loading:true,
					agentId:false,
					agentInfo:false
				}, ()=>{

					this.setState({
						loading:false,
						agentId:this.props.location.state.agentId
					}, ()=>{

						

							this.getAgentProfile();
							this.getWalletStatement();
							this.listLoans();
	
							this.timerWalletBalance = setTimeout(this.func=async()=> {
								await this.getWalletBalance();
								this.timerWalletBalance = setTimeout(this.func, 2000); // (*)
							}, 2000);
	
							
							this.timerFloatBalance = setTimeout(this.func=async()=> {
								await this.getFloatBalance();
								this.timerFloatBalance = setTimeout(this.func, 2000); // (*)
							}, 2000);
	
							this.getLoanStatistics(); 




						

					})

				})

				
          }
        }
           
      }

	  
	  componentWillUnmount()
	  {
		  clearTimeout(this.timerWalletBalance);
		  clearTimeout(this.timerFloatBalance);
	  }


      getAgentProfile=async()=>{
		  const server_response=await ajax.getAgentProfile(this.state.agentId);
          if(server_response.status==="OK")
          {
             this.setState({
                agentInfo:server_response.details
             })

          }else{
            this.setState({
                agentInfo:"404"
            })
          }
	  }
	  
	  getWalletStatement=async()=>{
		const server_response=await ajax.getWalletStatement(this.state.agentId);
		if(server_response.status==="OK")
		{
		   this.setState({
			  walletStatement:server_response.details['records']
		   })

		}else{
		  this.setState({
			walletStatement:"404"
		  })
		}
	}

	getWalletBalance=async()=>{
        const server_response=await ajax.getWalletBalance(this.state.agentId);
        if(server_response.status==="OK")
        {
           this.setState({
                walletBalance:server_response.details.amount_c
           })

        }
    }
	  
	
	getFloatBalance=async()=>{
		const server_response=await ajax.getFloatBalance(this.state.agentId);
        if(server_response.status==="OK")
        {
           this.setState({
                floatBalance:server_response.details.amount_c
           })

        }
	}
	
	onClickProfile=(user_id)=>{
        this.props.history.push({
          pathname:'/customer-profile',
          state:{agentId:user_id}
        })
      
	  }
	  

	  onClickAnalyse=()=>{
        this.props.history.push({
          pathname:'/performance-profile',
          state:{agentId:this.state.agentId}
        })
      
      }
      
	  
	  
	  listLoans=async()=>{
		const server_response=await ajax.getLoans(this.state.filter, this.state.page, this.state.agentId);
		if(server_response.status==="OK")
		{
		   this.setState({
			loanHistory:server_response.details.list,
			  metaData:server_response.details.meta
		   })

		}else{
		  this.setState({
			loanHistory:"404"
		  })
		}
	}


	getLoanStatistics=async()=>{
		const server_response=await ajax.clientPeriodicalLoanStatistics(this.state.agentId);
		if(server_response.status==="OK")
		{
		   this.setState({
			loanStatistics:server_response.details
		   })

		}
	}


	onClickResetPIN=()=>{
		this.setState({
			warnAction:false
		}, ()=>{

				this.setState({
					warnAction:<WarnDelete
					title="Reset User PIN?"
					description="You are about to reset this user's transaction PIN! Make sure you inform this user immediately to avoid security bleach."
					buttonLabel="Reset PIN"
					onConfirm={()=>this.onResetPIN()}/>
				}, ()=>{
					$("#modal_delete").modal('show');
				})

		})
	  }

	  resetToast=()=>{
		  this.setState({
			  toast:false
		  })
	  }

	  onResetPIN=async()=>{
		
		const server_response=await ajax.resetTransactionKey(this.state.agentId);
		$("#modal_delete").modal('hide');
		if(server_response.status==="OK")
		{
		   this.setState({
			toast:<Toast 
				message={server_response.details[0]} 
				onClose={()=>this.resetToast()}/>
		   })

		}else{
			this.setState({
				toast:<Toast 
						message={server_response.details[0]} 
						type={3} onClose={()=>this.resetToast()}/>
			})
		}
	  }


	  
	  onClickProfile=()=>{
		this.setState({
			currentOption:"PROFILE"
		})
  		}

		onClickLoanHistory=()=>{
			this.setState({
				currentOption:"LOANS"
			})
		}

		onClickWallet=()=>{
			this.setState({
				currentOption:"WALLET"
			})
		}

		onClickFloat=()=>{
			this.setState({
				currentOption:"FLOAT"
			})
		}

		onClickChangeLimit=()=>{
			this.setState({
				changeLimit:false
			}, ()=>{
				this.setState({
					changeLimit:<PopChangeLimit onConfirm={this.onChangeLimit}/>
				}, ()=>{
					$("#modal_change_limit").modal('show');
				})
			})
			
		}
		
		onChangeLimit=async(amount)=>{


			const server_response=await ajax.updateAgentLimit(this.state.agentId, amount);
			$("#modal_change_limit").modal('hide');
			if(server_response.status==="OK")
			{
			   this.setState({
				toast:<Toast 
					message={server_response.details[0]} 
					onClose={()=>this.resetToast()}/>
			   })
	
			}else{
				this.setState({
					toast:<Toast 
							message={server_response.details[0]} 
							type={3} onClose={()=>this.resetToast()}/>
				}, ()=>{


					this.setState({
						customerInfo:false
					}, ()=>{
		
						this.getCustomerProfile();
						this.getWalletStatement();
						this.listLoans();
					})

				})


				
			}

			
		
				
		}

		onClickLocateUser=()=>{
			this.setState({
				locateUser:false
			}, ()=>{
				this.setState({
					locateUser:<PopUserLocation userId={this.state.agentId}/>
				}, ()=>{
					$("#pop-user-location").modal('show');
				})
			})
			
		}
		

    
    render(){
        const agent=this.state.agentInfo
          return (
			<div className="page">
			{!this.state.loading && <>
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar back={()=>this.props.history.goBack()}/>
                <div className="container-fluid">
                <PageHeader 
                     title="Agent Profile"
					 previousPageTitle="Agents"
					 back={"/agent-records"}
					 options={this.state.menuOptions}
                  />
				  {this.state.toast}
				  {this.state.warnAction}
				  {this.state.changeLimit}
				  {this.state.locateUser}
				  
				   {!agent && <ActivityLoader/>}


				   {agent && agent!=="404" && 
					<div className="row">

						<div className="col-lg-4 col-md-12">
							<div className="card custom-card">
								<div className="card-body text-center">
									<div className="main-profile-overview widget-user-image text-center">
										<div className="main-img-user">
											{this.state.agentInfo.user.photo_name==="avatar.png" &&
												<InitialAvatar value={this.state.agentInfo.user.initials}/>}
												{this.state.agentInfo.user.photo_name!=="avatar.png" &&
												<img 
													src={this.state.agentInfo.user.photo}
												/>}
										</div>
									</div>
									<div className="item-user pro-user">
										<h4 className="pro-user-username text-dark mt-2 mb-0">
											{this.state.agentInfo.user.first_name + ' ' + this.state.agentInfo.user.last_name}
										</h4>
										<p className="pro-user-desc text-muted mb-1">Agent</p>
										<p className="user-info-rating">
											<a className="link"><i className="fa fa-star text-warning"> </i></a>
											<a className="link"><i className="fa fa-star text-warning"> </i></a>
											<a className="link"><i className="fa fa-star text-warning"> </i></a>
											<a className="link"><i className="fa fa-star text-warning"> </i></a>
											<a className="link"><i className="far fa-star text-warning"> </i></a>
										</p>
									
										<Link href="#" className="btn ripple btn-primary btn-sm"><i className="far fa-edit mr-1"></i>Edit</Link>
										<Link href="#" className="btn ripple btn-secondary btn-sm"><i className="fas fa-stop-circle"></i>Block</Link>
										<Link 
											to={{
												pathname:'/performance-profile',
												state:{agentId:this.state.agentId}
											  }}
											onClick={this.onClickAnalyse} className="btn ripple btn-success btn-sm">
												<i className="fas fa-chart-line"></i>Analyse
											</Link>

									</div>
								</div>
								<div className="card-footer p-0">
									<div className="row text-center">
										<div className="col-sm-6 border-right">
											<div className="description-block">
		  										<h5 className="description-header mb-1">{this.state.floatBalance}</h5>
												<span className="text-muted">Capital Float</span>
											</div>
										</div>
										<div className="col-sm-6">
											<div className="description-block">
		 									 <h5 className="description-header mb-1">{this.state.walletBalance}</h5>
												<span className="text-muted">Business Float</span>
											</div>
										</div>
									</div>
								</div>
							</div>


							<div className="card custom-card">
								<div className="card-header custom-card-header">
									<div>
										<h6 className="card-title mb-0">Account Summary</h6>
									</div>
								</div>
								<div className="card-body">
									<div className="main-profile-contact-list main-profile-work-list">
										<div className="media">
											<div className="media-logo bg-light text-dark">
												<i className="fa fa-users"></i>
											</div>
											<div className="media-body">
												<span>Total Customers</span>
												<div>
												<Link 
													className="orange" 
													to={
														{
															pathname:'/customer-records',
															state:{agent_id:this.state.agentId}
														}}>
													{this.state.agentInfo.agent.total_customers.total_c}
													</Link>
													
												</div>
											</div>
										</div>

									{this.state.loanStatistics && <>
										<div className="media">
											<div className="media-logo bg-light text-dark">
												<i className="fas fa-hands-helping"></i>
											</div>
											<div className="media-body">
												<span>Total Loans</span>
												<div>
													{this.state.loanStatistics.total_loan.amount_c}
													
												</div>
											</div>
										</div>

										<div className="media">
											<div className="media-logo bg-light text-dark">
												<i className="fas fa-exchange-alt"></i>
											</div>
											<div className="media-body">
												<span>Total Loan Payment</span>
												<div>
												{this.state.loanStatistics.total_paid.amount_c}
												</div>
											</div>
										</div>

										<div className="media">
											<div className="media-logo bg-light text-dark">
												<i className="fas fa-coffee"></i>
											</div>
											<div className="media-body">
												<span>Loan Balances</span>
												<div>
													{this.state.loanStatistics.balance.amount_c}
													
												</div>
											</div>
										</div>


										<div className="media">
											<div className="media-logo bg-light text-dark">
												<i className=" fas fa-chart-line"></i>
											</div>
											<div className="media-body">
												<span>Performance</span>
												<div>
													{this.state.loanStatistics.performance }%
													
												</div>
											</div>
										</div>


									</>}

									</div>
								</div>
							</div>
						
							<UserControls info={this.state.agentInfo.user}/>
                        
                        </div>
                  


                        <div className="col-lg-8 col-md-12">
						<AgentSummary info={this.state.agentInfo}/>
						{this.state.currentOption==="LOADING" && <ActivityLoader/>}
						{this.state.currentOption==="PROFILE" && <AgentProfileData info={this.state.agentInfo}/>}
						{this.state.currentOption==="WALLET" && <AgentWallet clientId={this.state.agentInfo.user.user_id}/>}
						{this.state.currentOption==="LOANS" && <AgentLoans clientId={this.state.agentInfo.user.user_id}/>}
						{this.state.currentOption==="FLOAT" && <AgentFloat clientId={this.state.agentInfo.user.user_id}/>}

					
					
						</div>




                    </div>}

				  

                  
                </div>
            </div>
            <RightSideBar/>
            <Footer/>
			</>}

			{this.state.loading && <ActivityLoader/>}
        </div>
    
            
          );  
   
    }
  }
  
  export default AgentProfile;
  