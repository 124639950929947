import React from 'react';
import ajax from '../../ajax';
import functions from '../../functions';
import DashCard from '../Common/DashCard';


class AgentSummary extends React.Component {
  
  state={
    userId:functions.sessionGuard(),
    agentInfo:this.props.info
  }


    
  render(){
    const agent=this.state.agentInfo.agent
      return (
        
        <div className="text-wrap">
        <div className="example bg-white">
            <div className="btn-list">
                <button type="button" className="btn ripple btn-primary btn-sm">
                    Loan Limit 
                    <span className="badge badge-light ml-2">{"UGX." + agent.loan_limit.amount_c}</span>
                </button>
               
                <button type="button" className="btn ripple btn-success btn-sm">
                    Commission Balance <span className="badge badge-light ml-2">{"UGX." + agent.commission_balance.amount_c}</span>
                </button>
              
    
            </div>
        </div>
        </div>
          
      );
    
 
  }
}

export default AgentSummary;