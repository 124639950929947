import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import ajax from '../../ajax'
import functions from '../../functions'
import ActivityLoader from '../../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import RightSideBar from '../../Components/Common/RightSideBar'
import Footer from '../../Components/Common/Footer'


class Customers extends React.Component {

    state={
        userId:functions.sessionGuard(),
        customerList:false,
        metaData:false,
        page:1,
        agentId:0,
        signingAgent:false
       
      }


      componentDidMount()
      {
        if(!this.props.location.state)
        {
          this.listCustomers();
        }else{
          this.setState({
            agentId:this.props.location.state.agent_id
          }, 
          ()=>{
                this.listCustomers();
                })
            }
        
      }


      listCustomers=async()=>{
          const server_response=await ajax.getCustomers(this.state.page, this.state.agentId);
          if(server_response.status==="OK")
          {
             this.setState({
                customerList:server_response.details.list,
                metaData:server_response.details.meta,
                signingAgent:server_response.details.signing_agent
             })

          }else{
            this.setState({
                customerList:"404"
            })
          }
      }


      onClickProfile=(record)=>{
        this.props.history.push({
          pathname:'/customer-profile',
          state:{customerId:record.user.user_id}
        })
      
      }
      

      onClickAgentProfile=(id)=>{
        this.props.history.push({
          pathname:'/agent-profile',
          state:{agentId:id}
        })
      
      }


      onClickNext=()=>{
        //increment page numbers
        const metaData=this.state.metaData;
        if(metaData.current_page*1+1<=metaData.total_pages*1)
        {
          this.setState({
            page:this.state.page+1,
            customerList:false

          }, ()=>{
            this.listCustomers();
          })
        }

      }

      onClickPrevious=()=>{
        const metaData=this.state.metaData;
        if(metaData.current_page*1>1)
        {
          this.setState({
            page:this.state.page-1,
            customerList:false

          }, ()=>{
            this.listCustomers();
          })
        }

      }

    docHeader=()=>{
      if(this.state.signingAgent)
      {
        return(
          <div>
            <h6 className="card-title mb-1">Agent's Customer Records</h6>
        <p className="text-muted card-sub-title">List of all Quickpost customers under {this.state.signingAgent.first_name + ' ' + this.state.signingAgent.last_name}</p>
          </div>
        )
      }else{
        return(
          <div>
              <h6 className="card-title mb-1">Customer Records</h6>
              <p className="text-muted card-sub-title">List of all Quickpost customers</p>
					</div>
        )
      }
    }

    
    render(){
        const list=this.state.customerList
        const metaData=this.state.metaData;
          return (
            <div className="page">
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar back={()=>this.props.history.goBack()}/>
                <div className="container-fluid">
                <PageHeader 
                     title="Customer Records"
                     previousPageTitle="Customers"
                  />
                  
                    <div className="row">
                      <div className="col-sm-12 col-xl-12 col-lg-12">
                     
                      <div className="card custom-card">
                          <div className="card-body">
                            <this.docHeader/>
                            <div className="table-responsive">
                              <table className="table table-bordered text-nowrap mb-0">
                                <thead>
                                  <tr>
                                    <th>#No</th>
                                    <th>Name</th>
                                    <th>Phone</th>
                                    <th>Loan Worth</th>
                                    <th>Total Loans</th>
                                    <th>Total Paid</th>
                                    <th>Loan Balance</th>
                                    <th>Agent Contact</th>
                                  </tr>
                                </thead>
                                <tbody>

                                {list && list!=="404" && list.map((item, key)=>
                                                              <tr key={key}>
                                    <td>{metaData.offset_count+key+1}</td>
                                    <td>
                                        <Link 
                                          className="orange" 
                                          to={
                                            {
                                              pathname:'/customer-profile',
                                              state:{customerId:item.user.user_id}
                                            }}
                                          >
                                          {item.user.first_name + ' ' + item.user.last_name}
                                          </Link>
                                    </td>
                                  
                                    <td>{item.user.phone}</td>
                                    <td>{item.member.loan_worth.amount_c}</td>
                                    <td>{item.member.loans.total_loan.amount_c}/=</td>
                                    <td>{item.member.loans.total_paid.amount_c}/=</td>
                                    <td>{item.member.loans.balance.amount_c}/=</td>
                                    <td>
                                                                  {item.member.agent_id*1>0 && 
                                                                      <Link className="orange" to="#" onClick={()=>this.onClickAgentProfile(item.member.agent_id)}>{item.member.agent_info.phone}</Link>
                                                                  }
                                                              </td>
                                  </tr>
                                                          )}	
                                  
                                </tbody>
                              </table>

                                                  {!this.state.customerList && <ActivityLoader/>}


                                  {metaData && <nav className="float-right">
                                    <ul className="pagination">
                                    <li className="page-item">
                                    <Link className="page-link" to="#" onClick={this.onClickPrevious}>Previous</Link>
                                    </li>
                                    <li className="page-item"><a className="page-link">{metaData.current_page}</a></li>
                                    <li className="page-item"><a className="page-link">of</a></li>
                                    <li className="page-item"><a className="page-link">{metaData.total_pages}</a></li>
                                    <li className="page-item">
                                        <Link className="page-link" to="#" onClick={this.onClickNext}>Next</Link>
                                        </li>
                                    </ul>
                                </nav>}
                            </div>
                          </div>
                        </div>
						

                          
                      </div>
                     
                    </div>
                  
                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
            
          );  
   
    }
  }
  
  export default Customers;
  