import { data } from 'jquery';
import React from 'react';
import ajax from '../../ajax';
import dictionary from '../../dictionary';
import functions from '../../functions';
import ActivityLoader from '../Common/ActivityLoader';
import AlertError from '../Common/AlertError';
import AlertSuccess from '../Common/AlertSuccess';
import SelectSearchWrap from '../Common/SelectSearchWrap';
import TextInput from '../Common/TextInput';
import TextInputWrap from '../Common/TextInputWrap';

class PopVerifyCustomer extends React.Component {

    state={
        userId:functions.sessionGuard(),
        amount:'',
        info:'',
        loading:false,
        data:this.props.data,
        verified:false,
        customerId:'',
        firstName:'',
        lastName:'',
        email:'',
        phone:'',
        documents:[],
        documentId:false,
        new:true,
        alertmess:false,
        gender:'',
        recommender:'',
        NIN:'',
        nokFirstName:'',
        nokLastName:'',
        nokContact:'',
        nokRelationship:'',
        nokAddress:'',
        dateOfBirth:''
    }

    componentDidMount()
    {
        const data=this.state.data;
        this.setState({
            firstName:data.first_name,
            lastName:data.last_name,
            phone:data.phone,
            email:data.email,
            gender:data.gender,
            verified:data.verified*1>0?true:false,
            recommender:data.referral_info?data.referral_info.phone:'',
            NIN:data.NIN,
            nokContact:data.next_of_kin?data.next_of_kin.contact:'',
            nokFirstName:data.next_of_kin?data.next_of_kin.first_name:'',
            nokLastName:data.next_of_kin?data.next_of_kin.last_name:'',
            nokRelationship:data.next_of_kin?data.next_of_kin.relationship:'',
            customerId:data.user_id,
            dateOfBirth:data.dob.db,
            nokAddress:data.next_of_kin.address


        })
    }

   

   
    onChangeFirstName = (event) => {
        this.setState({firstName: event.target.value});
      }
    onChangeLastName = (event) => {
    this.setState({lastName: event.target.value});
    }

    onChangeEmail = (event) => {
        this.setState({email: event.target.value});
    }

    onChangePhone = (event) => {
        this.setState({phone: event.target.value});
    }

    onChangeRecommender = (event) => {
        this.setState({recommender: event.target.value});
    }

    onChangeNIN = (event) => {
        this.setState({NIN: event.target.value});
    }

    onChangeNOKFirstName = (event) => {
        this.setState({nokFirstName: event.target.value});
    }

    onChangeNOKLastName = (event) => {
        this.setState({nokLastName: event.target.value});
    }

    onChangeNOKContact = (event) => {
        this.setState({nokContact: event.target.value});
    }

    onChangeNOKRship = (event) => {
        this.setState({nokRelationship: event.target.value});
    }

    onChangeGender = (selectedOption) => {
        this.setState({
            gender: selectedOption.value
        })
      }

      onClickVerify=()=>{
          this.setState({
              verified:!this.state.verified
          })
      }

      onChangeAddress = (event) => {
        this.setState({nokAddress: event.target.value});
        }

    onChangeDateOfBirth = (event) => {
        this.setState({dateOfBirth: event.target.value});
        }

    onChangeDocument = (event) => {
        this.setState({
         fileName:event.target.value
        })
         let files=event.target.files;
         let reader=new FileReader();
         reader.readAsDataURL(files[0]);
         reader.onload=(e)=>{
            const newDocs=this.state.documents;
            const documentId=this.state.documentId;
            const newItem={file:  e.target.result, document_id:documentId}

            newDocs.map((item, key)=>{
                if(item.document_id==this.state.documentId)
                {
                    newDocs[newDocs.indexOf(item)]=newItem;
                    this.setState({
                        new:false
                    })
                }
            })

          
            this.state.new && newDocs.push(newItem);
             this.setState({
                documents:newDocs,
                new:true
             })
         }
         
       }

       onSave=async(e)=>{
            e.preventDefault();
            const {
                phone, 
                email, 
                firstName, 
                lastName, 
                documents, 
                nokFirstName, 
                nokLastName, 
                nokContact,
                nokRelationship,
                recommender,
                NIN,
                gender,
                verified,
                customerId,
                dateOfBirth,
                nokAddress
            }=this.state;
            if(phone.length>0 && firstName.length>0 && lastName.length)
            {
                const nextOfKin={"first_name":nokFirstName, "last_name":nokLastName, "contact":nokContact, "relationship":nokRelationship, "address":nokAddress}
                const server_response=await ajax.updateCustomerProfile(
                    customerId,
                    firstName,
                    lastName,
                    dateOfBirth,
                    phone,
                    email,
                    documents,
                    gender,
                    NIN,
                    recommender,
                    nextOfKin,
                    verified?1:0
                );

                if(server_response.status==="OK")
                {
                    this.setState({
                        alertmess:<AlertSuccess message={server_response.details[0]}/>
                    })
                    this.props.onUpdate()
                }else{
                    this.setState({
                        alertmess:<AlertError message={server_response.details[0]}/>
                    })
                }

            }else{
                this.setState({
                    alertmess:<AlertError message="First Name and Last Name are required"/>
                })
            }
       }
     


       setDocumentId=(id)=>{
        this.setState({
            documentId:id
        })
    }


  render(){
    const  accessInfo=functions.readPermissions()
    const optionsGender=[{label:'Male', value:'Male'}, {label:'Female', value:'Female'}]
    
      return (
        <div className="modal" id="modal_verify_customer">
        <div className="modal-dialog modal-dialog-scrollable modal-lg  modal-dialog-centered" role="document">
            
        <div className="modal-content modal-content-demo shadow">
            <div className="modal-header">
                <h6 className="modal-title">Verify Customer Data</h6>

                
                {functions.findInObject(accessInfo, "CUST-VERIFY-0003") && <div 
                    className="switch-toggle d-flex" 
                    onClick={this.onClickVerify}
                    >
                    <span className="mr-auto">
                        <i className="fas fa-chevron-circle-right primary"/>&nbsp;
                        <b>Verified</b> &nbsp;&nbsp;
                    </span>
                    <div className="onoffswitch2">
                        <input 
                            type="radio" 
                            className="onoffswitch2-checkbox" 
                            checked={this.state.verified}
                            />
                        <label for="myonoffswitch" className="onoffswitch2-label"></label>
                    </div>
                </div>}

                <button aria-label="Close" className="close" data-dismiss="modal" type="button"><span aria-hidden="true">&times;</span></button>
            </div>
            <div className="modal-body">
                {this.state.alertmess}
               

                <div className="row">
						<div className="col-lg-12 col-md-12">
								
									<form method="post" onSubmit={this.onSave} className="row">
                                        
										<div className="col-md-6">
                                            <TextInputWrap 
                                                label="First Name"  
                                                onChange={()=>this.onChangeFirstName}
                                                value={this.state.firstName}
                                                />

                                            <TextInputWrap 
                                                label="Last Name"  
                                                onChange={()=>this.onChangeLastName}
                                                value={this.state.lastName}
                                                />

                                            <TextInputWrap 
                                                label="Date Of Birth"  
                                                onChange={()=>this.onChangeDateOfBirth}
                                                value={this.state.dateOfBirth}
                                                type="date"
                                                />
                                            
                                            <TextInputWrap 
                                                label="Mobile Phone (USSD Phone)"  
                                                onChange={()=>this.onChangePhone}
                                                value={this.state.phone}
                                                readOnly={functions.findInObject(accessInfo, "CUST-CRID-0003")?false:true}
                                                />

                                            <SelectSearchWrap 
                                                label="Gender"
                                                onChange={()=>this.onChangeGender}
                                                placeholder={this.state.gender}
                                                options={optionsGender}
                                                //required={true}
                                            />


                                            <TextInputWrap 
                                                label="Email"  
                                                onChange={()=>this.onChangeEmail}
                                                value={this.state.email}
                                                />


                                            <TextInputWrap 
                                                label="Recommender"  
                                                onChange={()=>this.onChangeRecommender}
                                                value={this.state.recommender}
                                                />

                                            <TextInputWrap 
                                                label="NIN"  
                                                onChange={()=>this.onChangeNIN}
                                                value={this.state.NIN}
                                                />


                                           
										</div>
										<div className="col-md-6">

                                      
                                        <TextInputWrap 
                                                label="Next Of Kin First Name"  
                                                onChange={()=>this.onChangeNOKFirstName}
                                                value={this.state.nokFirstName}
                                                />

                                        <TextInputWrap 
                                                label="Next Of Kin Last Name"  
                                                onChange={()=>this.onChangeNOKLastName}
                                                value={this.state.nokLastName}
                                                />

                                        <TextInputWrap 
                                                label="Next Of Kin Contact"  
                                                onChange={()=>this.onChangeNOKContact}
                                                value={this.state.nokContact}
                                                />

                                        <TextInputWrap 
                                                label="Relationship With Next Of Kin"  
                                                onChange={()=>this.onChangeNOKRship}
                                                value={this.state.nokRelationship}
                                                />

                                        <TextInputWrap 
                                                label="Next Of Kin Address"  
                                                onChange={()=>this.onChangeAddress}
                                                value={this.state.nokAddress}
                                                />

                                        {this.state.data.documents.map((item, key)=>
                                         <TextInputWrap 
                                            onClick={()=>this.setDocumentId(item.document_id)}
                                            key={key}
                                            type="file"
                                            label={item.document_name}  
                                            onChange={()=>this.onChangeDocument }
                                            />
                                                            
                                        )}

                                       
											
										</div>
										
									</form>
								</div>



							</div>
					



            
            </div>
           {!this.state.loading && 
           <div className="modal-footer">


                <button className="btn ripple btn-primary" type="button" onClick={this.onSave}>Save Changes</button>
                <button className="btn ripple btn-secondary" type="button" data-dismiss="modal">Close</button>
            </div>}

            {this.state.loading && 
            <div className="modal-footer">
                <ActivityLoader/>
            </div>}
        </div>


        </div>
    </div>
      )
    
 
  }
}

export default PopVerifyCustomer;