import React from 'react';
import ajax from '../../ajax';
import functions from '../../functions';
import ActivityLoader from '../Common/ActivityLoader';
import $ from 'jquery';
import MdlLoanStatement from '../MdlLoanStatement';
import PageNav from '../Common/PageNav';


class AgentLoans extends React.Component {
  
  state={
    userId:functions.sessionGuard(),
    clientId:this.props.clientId,
    loanList:false,
    metaData:false,
    filter:"ALL",
    page:1,
    title:"All Loans",
    loanRecord:'',
    selectedLoanId:'',
    statement:false,
    pagination:false
  }


  componentDidMount()
  {
    this.listLoans();
  }


  listLoans=async()=>{
    switch(this.state.filter){
      case "ALL":
        var server_response=await ajax.filterAllLoans(this.state.clientId, this.state.page);
      break;
      case "BUSINESS":
        var server_response=await ajax.filterBusinessLoans(this.state.clientId, this.state.page);
      break;
      case "PERSONAL":
        var server_response=await ajax.filterPersonalLoans(this.state.clientId, this.state.page);
      break;
      case "DEFAULTED":
        var server_response=await ajax.filterDefaultedLoans(this.state.clientId, this.state.page);
      break;
      case "OUTSTANDING":
        var server_response=await ajax.filterOutstandingLoans(this.state.clientId, this.state.page);
      break;

    }
     // console.log(server_response.details)
      if(server_response.status==="OK")
      {
         this.setState({
            loanList:server_response.details.list,
            metaData:server_response.details.meta
         }, ()=>this.paginate())

      }else{
        this.setState({
            loanList:"404"
        })
      }
  }


  onSelectRecord=(selectedLoanId)=>{
    this.setState({
        selectedLoanId})
    }

   //==============NAVIGATION CONTROLS START===========================
   onClickNext=()=>{
    //increment page numbers
    const metaData=this.state.metaData;
    if(metaData.current_page*1+1<=metaData.total_pages*1)
    {
      this.setState({
        page:this.state.page+1,
        loanList:false

      }, ()=>{
        this.listLoans();
      })
    }

  }

  onClickPage=(page)=>{
    this.setState({
        page:page,
        loanList:false
    }, ()=>this.listLoans())
}

  onClickPrevious=()=>{
    const metaData=this.state.metaData;
    if(metaData.current_page*1>1)
    {
      this.setState({
        page:this.state.page-1,
        loanList:false

      }, ()=>{
        this.listLoans();
      })
    }

  }

  paginate=()=>{
    if(this.state.metaData)
    {
      this.setState({
        pagination:false
      }, ()=>{

        this.setState({
          pagination:<PageNav
          totalPages={this.state.metaData.total_pages}
          pageList={this.state.metaData.list_of_pages}
          currentPage={this.state.page}
          onClickNext={this.onClickNext}
          onClickPage={this.onClickPage}
          onClickPrevious={this.onClickPrevious}
          />
        })
        
      })
       
    }
  }

  //==============NAVIGATION CONTROLS END===========================



  onClickLoan=(record)=>{
    this.setState({
      statement:false
    }, ()=>{
      this.setState({
        statement:<MdlLoanStatement loan={record}/>
      }, ()=>{
        $("#modal_loan").modal('show');
      })
    })
  }


    
  render(){
    const list=this.state.loanList
    const metaData=this.state.metaData;
      return (
        <div class="row">
             {this.state.statement}
        <div class="col-sm-12 col-xl-12 col-lg-12">
            <div class="card custom-card">
                <div class="card-body">
                    <div>
                        <h6 class="card-title mb-1">Loan History</h6>
                        <p class="text-muted card-sub-title">List of all loans given out by this agent</p>
                    </div>
                    <div class="table-responsive">
                    <table className="table table-bordered text-nowrap mb-0">
                                <thead>
                                  <tr>
                                  <th>#No</th>
                                    <th>Loan Type</th>
                                    <th>Loan ID</th>
                                    <th>Customer Name</th>
                                    <th>Customer Phone</th>
                                    <th>Loan Date</th>
                                    <th>Deadline</th>
                                    <th>Princ(UGX)</th>
                                    <th>Interest(UGX)</th>
                                    <th>Paid</th>
                                    <th>Fine</th>
                                    <th>Balance</th>
                                  </tr>
                                </thead>
                                <tbody>

                                {list && list!=="404" && list.map((item, key)=>
                                    <tr 
                                    key={key}  
                                    className={`${this.state.selectedLoanId==item.loan_id?'flag-row':''}`}
                                    onClick={()=>this.onSelectRecord(item.loan_id)}>
                                  <td>{metaData.offset_count + key+1}</td>

                                  <td>
                                      {item.package_info.loan_type.type_name}
                                  </td>

                                  <td>
                                      {/* <Link 
                                        className="orange" 
                                        to={
                                              {
                                                  pathname:'/loan-profile',
                                                  state:{loan_id:item.loan_id}
                                              }
                                            }>
                                      {item.loan_code}
                                      </Link> */}
                                      <a 
                                        className="link" 
                                        to="#"
                                        onClick={()=>this.onClickLoan(item)}
                                          >
                                      {item.loan_code}
                                      </a> 
                      
                                  </td>
                                  <td>
                                      {item.customer_info.first_name + " " + item.customer_info.last_name}
                                  </td>
                                  <td>
                                      {item.customer_info.phone}
                                  </td>
                                  <td>{item.loan_date.short_date}</td>
                                  <td>{item.deadline.short_date}</td>


                                  <td>{item.loan_principal.amount_c}</td>
                                  <td>{item.loan_interest.amount_c}</td>


                                  <td>{item.total_paid.amount_c}</td>
                                  <td>{item.loan_fine.amount_c}</td>
                                  <td>{item.loan_balance.amount_c}</td>
                                 
                                </tr>    
                                
                                )}	
                                  
                                </tbody>
                              </table>





                    {this.state.pagination && this.state.pagination}
                    {!this.state.loanList && <ActivityLoader/>}




                    </div>
                </div>
            </div>
        </div>
    </div>
      );
    
 
  }
}

export default AgentLoans