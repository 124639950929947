import React from 'react'
import LeftSideBar from '../Components/Common/LeftSideBar'
import TopBar from '../Components/Common/TopBar'
import PageHeader from '../Components/Common/PageHeader'
import ajax from '../ajax'
import functions from '../functions'
import ActivityLoader from '../Components/Common/ActivityLoader'
import { Link } from 'react-router-dom'
import RightSideBar from '../Components/Common/RightSideBar'
import Footer from '../Components/Common/Footer'

class Agents extends React.Component {

    state={
        userId:functions.sessionGuard(),
        agentList:false,
        metaData:false,
        page:1
       
      }

      componentDidMount()
      {
        this.listAgents();
      }


      listAgents=async()=>{
          const server_response=await ajax.getAgents(this.state.page);
          if(server_response.status==="OK")
          {
             this.setState({
                agentList:server_response.details.list,
                metaData:server_response.details.meta
             })

          }else{
            this.setState({
                agentList:"404"
            })
          }
      }


      onClickProfile=(record)=>{
        this.props.history.push({
          pathname:'/agent-profile',
          state:{agentId:record.user.user_id}
        })
      

      }
      

      onClickNext=()=>{
        //increment page numbers
        const metaData=this.state.metaData;
        if(metaData.current_page*1+1<=metaData.total_pages*1)
        {
          this.setState({
            page:this.state.page+1,
            agentList:false

          }, ()=>{
            this.listAgents();
          })
        }

      }

      onClickPrevious=()=>{
        const metaData=this.state.metaData;
        if(metaData.current_page*1>1)
        {
          this.setState({
            page:this.state.page-1,
            agentList:false

          }, ()=>{
            this.listAgents();
          })
        }

      }


    
    render(){
        const list=this.state.agentList
        const metaData=this.state.metaData;

          return (
            <div className="page">
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar back={()=>this.props.history.goBack()}/>
                <div className="container-fluid">
                <PageHeader 
                     title="Agent Records"
                     previousPageTitle="Agents"
                  />
                  
                    <div className="row">
                      <div className="col-sm-12 col-xl-12 col-lg-12">
                     
                          <div className="card custom-card">
                            <div className="card-body">
                              <div>
                                <h6 className="card-title mb-1">Agent Records</h6>
                                <p className="text-muted card-sub-title">List of all Quickpost agents</p>
                              </div>
                              <div className="table-responsive">
                                <table className="table table-bordered text-nowrap mb-0">
                                  <thead>
                                    <tr>
                                      <th>#No</th>
                                      <th>Name</th>
                                      <th>Phone</th>
                                      <th>User ID</th>
                                      <th>B. Float</th>
                                      <th>C. Float</th>
                                      <th>Comm. Balance</th>
                                      <th>Customers</th>
                                      <th>Max Loan</th>
                                      {/* <th>Total Loans</th>
                                      <th>Total Paid</th>
                                      <th>Loan Balances</th> */}
                                    </tr>
                                  </thead>
                                  <tbody>

                                  {list && list!=="404" && list.map((item, key)=>
                                    <tr key={key}>
                                    <td>{metaData.offset_count+key+1}</td>
                                      <td>
                                          <Link 
                                              className="orange" 
                                              to={
                                                {
                                                  pathname:'/agent-profile',
                                                  state:{agentId:item.user.user_id}
                                                }} 
                                              >{item.user.first_name + ' '+ item.user.last_name}</Link>
                                      </td>
                                      <td>{item.user.phone}</td>
                                      <td>{item.user.user_id}</td>
                                      <td>{item.agent.wallet_balance.amount_c}</td>
                                      <td>{item.agent.capital_float.amount_c}</td>
                                      <td>{item.agent.commission_balance.amount_c}</td>
                                      <td>
                                        <Link 
                                          className="orange" 
                                          to={
                                            {
                                                pathname:'/customer-records',
                                                state:{agent_id:item.user.user_id}
                                            }}>
                                          {item.agent.total_customers.total_c}
                                          </Link>
                                      </td>
                                      <td>{item.agent.loan_limit.amount_c}</td>
                                      {/* <td>{item.agent.loans.total_loan.amount_c}</td>
                                      <td>{item.agent.loans.total_paid.amount_c}</td>
                                      <td>{item.agent.loans.balance.amount_c}</td> */}

                                    </tr>
                                    )}	
                                    
                                  </tbody>
                                </table>

                                      {!this.state.agentList && <ActivityLoader/>}


                                      {metaData && <nav className="float-right">
                                    <ul className="pagination">
                                    <li className="page-item">
                                    <Link className="page-link" to="#" onClick={this.onClickPrevious}>Previous</Link>
                                    </li>
                                    <li className="page-item"><a className="page-link">{metaData.current_page}</a></li>
                                    <li className="page-item"><a className="page-link">of</a></li>
                                    <li className="page-item"><a className="page-link">{metaData.total_pages}</a></li>
                                    <li className="page-item">
                                        <Link className="page-link" to="#" onClick={this.onClickNext}>Next</Link>
                                        </li>
                                    </ul>
                                </nav>}

                              </div>
                            </div>
                          </div>
                        
                      </div>
                     
                    </div>
                  
                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
            
          );  
   
    }
  }
  
  export default Agents;
  