import React from 'react';
import ajax from '../../ajax';
import functions from '../../functions';
import DashCard from '../Common/DashCard';


class CustomerProfileData extends React.Component {
  
  state={
    userId:functions.sessionGuard(),
    customerInfo:this.props.info
  }


    
  render(){
   const documents=this.state.customerInfo.user.documents;
   const next_of_kin=this.state.customerInfo.user.next_of_kin;
      return (
       
        <div className="card custom-card">
          <div className="">
            <div className="main-content-body main-content-body-contacts">
             
              <div className="main-contact-info-body">
                
                <div className="media-list pb-lg-0">
                  <div className="media">
                    <div className="media-body">
                      <div>
                        <label>Full Name</label> 
                        <span className="tx-medium">{this.state.customerInfo.user.first_name + ' ' + this.state.customerInfo.user.last_name}</span>
                      </div>
                      <div>
                        <label>Gender</label> 
                        <span className="tx-medium">{this.state.customerInfo.user.gender}</span>
                      </div>
                    </div>
                  </div>
                  <div className="media">
                    <div className="media-body">
                      <div>
                        <label>Birthday</label> 
                        <span className="tx-medium">{this.state.customerInfo.user.dob.picker}</span>
                      </div>
                      <div>
                        <label>Phone</label> 
                        <span className="tx-medium">{this.state.customerInfo.user.phone}</span>
                      </div>
                    </div>
                  </div>
                  
                  <div className="media mb-0">
                    <div className="media-body">
                      <div>
                        <label>Email</label> 
                        <span className="tx-medium">{this.state.customerInfo.user.email}</span>
                      </div>
                      <div>
                        <label>Agent</label> 
                        <span className="tx-medium">
                            {this.state.customerInfo.agent_info && this.state.customerInfo.agent_info.first_name + " " + this.state.customerInfo.agent_info.last_name + "(" + this.state.customerInfo.agent_info.phone + ")"}
                            {!this.state.customerInfo.agent_info && "n/a"}
                          </span>
                      </div>
                    </div>
                  </div>

                  <div className="media">
                    <div className="media-body">
                      <div>
                        <label>Current Address</label> 
                        <span className="tx-medium">{this.state.customerInfo.user.address.address}</span>
                      </div>
                    </div>
                  </div>



                  <div className="media">
                    <div className="media-body">
                      <div>
                        <label>Reg. Date</label> 
                        <span className="tx-medium">{this.state.customerInfo.user.reg_date}</span>
                      </div>
                      <div>
                        <label>Acc. Code</label> 
                        <span className="tx-medium">{this.state.customerInfo.user.account_number}</span>
                      </div>
                    </div>
                  </div>


                  
                  <div className="media">
                    <div className="media-body">
                      <div>
                        <label>National ID</label> 
                        <span className="tx-medium">{this.state.customerInfo.user.NIN}</span>
                      </div>
                      <div>
                        <label>Recommender</label> 
                        <span className="tx-medium">
                          {this.state.customerInfo.referral_info && this.state.customerInfo.referral_info.first_name + " " + this.state.customerInfo.referral_info.last_name + "(" + this.state.customerInfo.referral_info.phone + ")"}
                          {!this.state.customerInfo.referral_info && "n/a"}
                          </span>
                      </div>
                    </div>
                  </div>


                  <div className="media">
                    <div className="media-body">
                      <div>
                        <label>Current Device Token</label> 
                        <span className="tx-medium">{this.state.customerInfo.user.device}</span>
                      </div>
                     
                    </div>
                  </div>




                   
                  <div className="media">
                    <div className="media-body">
                      <div>
                        <label>Next Of Kin Name</label> 
                        <span className="tx-medium">
                          {next_of_kin && next_of_kin.last_name + ' ' + next_of_kin.first_name || "n/a"}
                        </span>
                      </div>
                    
                      <div>
                        <label>Next Of Kin Contact</label> 
                        <span className="tx-medium">
                        {next_of_kin && next_of_kin.contact || "n/a"}

                          </span>
                      </div>
                    </div>
                  </div>




                  <div className="media">
                    <div className="media-body">
                      <div>
                        <label>Next Of Kin Address</label> 
                        <span className="tx-medium">
                          {next_of_kin && next_of_kin.address || "n/a"}
                        </span>
                      </div>
                      <div>
                        <label>R/ship with Next Of Kin</label> 
                        <span className="tx-medium">
                          {next_of_kin && next_of_kin.relationship || "n/a"}
                          </span>
                      </div>

                    </div>
                  </div>





                </div>




                <div className="main-content-label tx-13 mg-b-20 mt-3">
											Documents
										</div>
										<div className="row">
                      {documents.map((item, key)=>
                      //className="col-6 col-md-3"
                      	<div key={key}>
                        <img 
                          alt="Document" 
                          title={item.document_name}
                          className="img-thumbnail pointer" 
                          src={item.file_path}
                          width="100px"
                          style={{float:'left'}}
                          onClick={()=>this.props.onClickImage(item.file_path, item.document_name)}
                          />
											</div>
                      
                      )}
										</div>








              </div>
            </div>
          </div>
       
        </div>

      );
    
 
  }
}

export default CustomerProfileData