import React from 'react';
import ajax from '../../ajax';
import functions from '../../functions';
import ActivityLoader from '../Common/ActivityLoader';
import PageNav from '../Common/PageNav';


class CustomerWallet extends React.Component {
  
  state={
    userId:functions.sessionGuard(),
    clientId:this.props.clientId,
    transactionList:false,
    metaData:false,
    page:1,
    pagination:false
  }


  componentDidMount()
  {
      this.listTransactions()
  }

  listTransactions=async()=>{
    const server_response=await ajax.getGeneralWalletStatement(this.state.clientId, this.state.page);
    if(server_response.status==="OK")
    {
      this.setState({
            transactionList:server_response.details.list,
            metaData:server_response.details.meta
      }, ()=>this.paginate())

    }else{
      this.setState({
      transactionList:"404"
      })
    }
    
  }


  //==============NAVIGATION CONTROLS START===========================
  onClickNext=()=>{
    //increment page numbers
    const metaData=this.state.metaData;
    if(metaData.current_page*1+1<=metaData.total_pages*1)
    {
      this.setState({
        page:this.state.page+1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  onClickPage=(page)=>{
    this.setState({
        page:page,
        transactionList:false
    }, ()=>this.listTransactions())
}

  onClickPrevious=()=>{
    const metaData=this.state.metaData;
    if(metaData.current_page*1>1)
    {
      this.setState({
        page:this.state.page-1,
        transactionList:false

      }, ()=>{
        this.listTransactions();
      })
    }

  }

  paginate=()=>{
    if(this.state.metaData)
    {
      this.setState({
        pagination:false
      }, ()=>{

        this.setState({
          pagination:<PageNav
          totalPages={this.state.metaData.total_pages}
          pageList={this.state.metaData.list_of_pages}
          currentPage={this.state.page}
          onClickNext={this.onClickNext}
          onClickPage={this.onClickPage}
          onClickPrevious={this.onClickPrevious}
          />
        })
        
      })
       
    }
  }

  //==============NAVIGATION CONTROLS END===========================

    
  render(){
    const list=this.state.transactionList;
      return (
        <div class="row">
        <div class="col-sm-12 col-xl-12 col-lg-12">
            <div class="card custom-card">
                <div class="card-body">
                    <div>
                        <h6 class="card-title mb-1">Wallet</h6>
                        <p class="text-muted card-sub-title">Customer wallet statement</p>
                    </div>
                    <div class="table-responsive">
                    <table className="table table-bordered text-nowrap mb-0 table-ellipsis">
                        <thead>
                            <tr>
                            <th>Date</th>
                            <th>Trans ID</th>
                            <th>description</th>
                            <th>Cr.</th>
                            <th>Dr.</th>
                            <th>Balance</th>
                            </tr>
                        </thead>
						            {list && list!=="404" &&  
                            <tbody>
                            {list.map((item, key)=>
                                <tr key={key}>
                                <td>{item.trans_date.long_date}</td>
                                <td>{item.trans_id}</td>
                                <td>
                                <span>{item.description}</span>
                                </td>
                                <td>{item.cash_in.amount*1>0 && item.cash_in.amount_c}</td>
                                <td>{item.cash_out.amount*1>0 && item.cash_out.amount_c}</td>
                                <td><b>{item.balance.amount_c}</b></td>
                                </tr>
                                )}	

                               

                            </tbody>}
					              </table>


                        {this.state.pagination && this.state.pagination}

                        {!this.state.transactionList && <ActivityLoader/>}
                    </div>
                </div>
            </div>
        </div>
    </div>
      );
    
 
  }
}

export default CustomerWallet