import React from 'react'
import LeftSideBar from '../../Components/Common/LeftSideBar'
import Footer from '../../Components/Common/Footer'
import TopBar from '../../Components/Common/TopBar'
import PageHeader from '../../Components/Common/PageHeader'
import functions from '../../functions'
import RightSideBar from '../../Components/Common/RightSideBar'
import PopWithdrawReserve from '../../Components/Reserve/PopWithdrawReserve'
import $ from 'jquery';
import PopTransferFloat from '../../Components/Float/PopTransferFloat'
import Toast from '../../Components/Common/Toast'
import ajax from '../../ajax'
import PopNewReserveRequest from '../../Components/Reserve/PopNewReserveRequest'
import ProductSales from '../../Components/Products/ProductSales'
import SaleRevenueCard from '../../Components/Products/SaleRevenueCard'
import SaleCostCard from '../../Components/Products/SaleCostCard'
import SaleGrossProfitCard from '../../Components/Products/SaleGrossProfitCard'
import WeeklySalesGraph from '../../Components/Products/WeeklySalesGraph'
import MonthlySalesGraph from '../../Components/Products/MonthlySalesGraph'
import RecentSales from '../../Components/Products/RecentSales'
import BillsEarningStat from '../../Components/Products/BillsEarningStat'
import AirtimeEarningStat from '../../Components/Products/AirtimeEarningStat'
import CyberEarningStat from '../../Components/Products/CyberEarningStat'

class DashboardProducts extends React.Component {

    state={
      userId:functions.sessionGuard(),
      withdrawReserve:false,
      transferFloat:false,
      toast:false,
      accessInfo:functions.readPermissions(),
      reserveRequest:false,
      graphView:"WEEK"
    }

    onClickWithdrawReserve=()=>{
      this.setState({
        withdrawReserve:false
      }, ()=>{
        this.setState({
          withdrawReserve:<PopWithdrawReserve onConfirm={this.onWithdrawReserve}/>
        }, ()=>{
          $("#modal_withdraw_reserve").modal('show');
        })
      })
    }


    onClickTransferFloat=()=>{
      this.setState({
        transferFloat:false
      }, ()=>{
        this.setState({
          transferFloat:<PopTransferFloat onConfirm={this.onTransferFloat}/>
        }, ()=>{
          $("#modal_transfer_float").modal('show');
        })
      })
    }

    resetToast=()=>{
		  this.setState({
			  toast:false
		  })
	  }


    onWithdrawReserve=async(amount)=>{
      const server_response=await ajax.withdrawReserve(amount);
			$("#modal_withdraw_reserve").modal('hide');
			if(server_response.status==="OK")
			{
			   this.setState({
				toast:<Toast 
					message={server_response.details[0]} 
					onClose={()=>this.resetToast()}/>
			   })
	
			}else{
				this.setState({
					toast:<Toast 
							message={server_response.details[0]} 
							type={2} onClose={()=>this.resetToast()}/>
        })
      }
    }

    onTransferFloat=async(amount, phone)=>{
      const server_response=await ajax.purchaseFloatForAgent(phone, amount);
			$("#modal_transfer_float").modal('hide');
			if(server_response.status==="OK")
			{
			   this.setState({
				toast:<Toast 
					message={server_response.details[0]} 
					onClose={()=>this.resetToast()}/>
			   })
	
			}else{
				this.setState({
					toast:<Toast 
							message={server_response.details[0]} 
							type={2} onClose={()=>this.resetToast()}/>
        })
      }
    }

   
    buttons=()=>{
      return(
        <div className="btn btn-list">
          
{/*              
                <a className="btn ripple btn-success" href="#" onClick={this.onClickNewReserveRequest}>
                  <i className="fe fe-external-link"></i> New Requisition
                </a> */}
			</div>
      )
    }


    onClickNewReserveRequest=()=>{
      this.setState({
        transferFloat:false
      }, ()=>{
        this.setState({
          transferFloat:<PopNewReserveRequest/>
        }, ()=>{
          $("#modal_new_reserve_request").modal('show');
        })
      })
    }


    onChangeGraphView=(graphView)=>{
      this.setState({graphView})
    }

    
    render(){
     
       
          return (
            <div className="page">
            <LeftSideBar/>
            <div className="main-content side-content pt-0" onClick={functions.closeSystemDrawer}>
            <TopBar/>
                <div className="container-fluid">
                <PageHeader 
                    title="Products"
                    previousPageTitle="Dashboard"
                    buttons={<this.buttons/>}
                  />
                  {this.state.withdrawReserve}
                  {this.state.transferFloat}
                  {this.state.toast}
                  {this.state.reserveRequest}
                  
                    <div className="row">
                      <ProductSales/>
                      <SaleRevenueCard/>
                      <SaleCostCard/>
                      <SaleGrossProfitCard/>
                    </div>
                    <div className="row">
                      <div className="col-sm-12 col-xl-8 col-lg-8">
                      {this.state.graphView=="WEEK" && <WeeklySalesGraph onChangeView={this.onChangeGraphView}/>}
                       {this.state.graphView=="MONTH" && <MonthlySalesGraph onChangeView={this.onChangeGraphView}/>}
                       <RecentSales/>
                      </div>
                      <div className="col-sm-12 col-xl-4 col-lg-4">
                          <BillsEarningStat/>
                          <AirtimeEarningStat/>
                          <CyberEarningStat/>
                      </div>
                    </div>
                  


                </div>
            </div>
            <RightSideBar/>
            <Footer/>
        </div>
    
            
          );  
   
    }
  }
  
  export default DashboardProducts