import React from 'react';
import ajax from '../../ajax';
import functions from '../../functions';
import DashCard from '../Common/DashCard';
import PopVerifyAction from '../Common/PopVerifyAction';


class UserControls extends React.Component {
  
  state={
    userId:functions.sessionGuard(),
    userInfo:this.props.info,
    exitButton:false,
    userVerified:false,
    verification:false,
    loanActivity:false,
    walletActivity:false,
    walletWithdraws:false

  }

  componentDidMount()
  {
      const userInfo=this.state.userInfo;
      this.setState({
          exitButton:userInfo.exit_button*1===0?false:true,
          userVerified:userInfo.verified*1===0?true:false,
          loanActivity:userInfo.loan_activity*1===1?true:false,
          walletActivity:userInfo.wallet_activity*1===1?true:false,
          walletWithdraws:userInfo.enable_withdraws*1===1?true:false

      })
  }

  onUpdateLogoutControl=(value)=>{
      this.setState({
          exitButton:value
      })
  }

  onClickEnableLogout=()=>{

    this.setState({
        verification:false
    }, ()=>{

        this.setState({
            verification:<PopVerifyAction
              title="Verify Action"
              description="Enter your account password to verify logout control update"
              value={this.state.exitButton?"0":"1"}
              requestId={this.state.userInfo.user_id}
              action={"VERIFY-LOGOUT-CONTROL"}
              onUpdate={this.onUpdateLogoutControl}
            />
        }, ()=>{
          functions.openPopup('pop-verify-action');
    
        })
    })
   
  }


  onUpdateRequireDocuments=(value)=>{
    this.setState({
        userVerified:value
    })
    }

  onClickRequireDocuments=()=>{

    this.setState({
        verification:false
    }, ()=>{

        this.setState({
            verification:<PopVerifyAction
              title="Verify Action"
              description="Your account password is required for document verification"
              value={this.state.userVerified?"1":"0"}
              requestId={this.state.userInfo.user_id}
              action={"REQUIRE-DOCUMENTS-CONTROL"}
              onUpdate={this.onUpdateRequireDocuments}
            />
        }, ()=>{
          functions.openPopup('pop-verify-action');
    
        })
    })
   
  }

  onUpdateLoanActivity=(value)=>{
    this.setState({
        loanActivity:value
    })
    }

  onClickLoanActivity=()=>{

    this.setState({
        verification:false
    }, ()=>{

        this.setState({
            verification:<PopVerifyAction
              title="Verify Action"
              description="Your account password is required to update loan activity"
              value={this.state.loanActivity?"0":"1"}
              requestId={this.state.userInfo.user_id}
              action={"LOAN-ACTIVITY-CONTROL"}
              onUpdate={this.onUpdateLoanActivity}
            />
        }, ()=>{
          functions.openPopup('pop-verify-action');
    
        })
    })
   
  }


  onUpdateWalletActivity=(value)=>{
    this.setState({
        walletActivity:value
    })
    }


  onClickWalletActivity=()=>{

    this.setState({
        verification:false
    }, ()=>{

        this.setState({
            verification:<PopVerifyAction
              title="Verify Action"
              description="Your account password is required to update wallet activity"
              value={this.state.walletActivity?"0":"1"}
              requestId={this.state.userInfo.user_id}
              action={"WALLET-ACTIVITY-CONTROL"}
              onUpdate={this.onUpdateWalletActivity}
            />
        }, ()=>{
          functions.openPopup('pop-verify-action');
    
        })
    })
   
  }



  onUpdateWalletWithdraws=(value)=>{
    this.setState({
        walletWithdraws:value
    })
    }


  onClickWalletWithdraws=()=>{

    this.setState({
        verification:false
    }, ()=>{

        this.setState({
            verification:<PopVerifyAction
              title="Verify Action"
              description="Your account password is required to update user wallet-withdraws"
              value={this.state.walletWithdraws?"0":"1"}
              requestId={this.state.userInfo.user_id}
              action={"WALLET-WITHDRAW-CONTROL"}
              onUpdate={this.onUpdateWalletWithdraws}
            />
        }, ()=>{
          functions.openPopup('pop-verify-action');
    
        })
    })
   
  }


    
  render(){
      const  accessInfo=functions.readPermissions()
      return (
       <>
       {this.state.verification}
       {functions.findInObject(accessInfo, "USER-CTRL-0001") &&
        <div className="card custom-card">
            <div className="card-body text-center">
          
       
     

            <div className="swichermainleft col-12">

                <div className="switch_section">
                    
                {functions.findInObject(accessInfo, "USER-CTRL-0002") &&
                        <div 
                            className="switch-toggle d-flex" 
                            >
                            <span className="mr-auto">
                                <i className="fas fa-chevron-circle-right primary"/>&nbsp;
                                Enable Multiple Devices</span>
                            <div className="onoffswitch2">
                                <input 
                                    type="radio" 
                                    className="onoffswitch2-checkbox" 
                                    //checked={this.state.walletActivity}
                                    />
                                <label for="myonoffswitch" className="onoffswitch2-label"></label>
                            </div>
                        </div>}

                {functions.findInObject(accessInfo, "USER-CTRL-0003") &&
                        <div 
                            className="switch-toggle d-flex" 
                            onClick={this.onClickLoanActivity}
                            >
                            <span className="mr-auto">
                                <i className="fas fa-chevron-circle-right primary"/>&nbsp;
                                Enable Loan Activity</span>
                            <div className="onoffswitch2">
                                <input 
                                    type="radio" 
                                    className="onoffswitch2-checkbox" 
                                    checked={this.state.loanActivity}
                                    />
                                <label for="myonoffswitch" className="onoffswitch2-label"></label>
                            </div>
                        </div>}

                        {functions.findInObject(accessInfo, "USER-CTRL-0004") &&
                        <div 
                            className="switch-toggle d-flex" 
                            >
                            <span className="mr-auto">
                                <i className="fas fa-chevron-circle-right primary"/>&nbsp;
                                Require User Documents
                                </span>
                            <div 
                                className="onoffswitch2"
                                onClick={this.onClickRequireDocuments}
                                >
                                <input 
                                    type="radio" 
                                    className="onoffswitch2-checkbox" 
                                    checked={this.state.userVerified}
                                    />
                                <label for="myonoffswitch" className="onoffswitch2-label"></label>
                            </div>
                        </div>}

                        {functions.findInObject(accessInfo, "USER-CTRL-0005") &&
                        <div 
                            className="switch-toggle d-flex" 
                            >
                            <span className="mr-auto">
                                <i className="fas fa-chevron-circle-right primary"/>&nbsp;
                                Enable Wallet Transactions
                            </span>
                            <div 
                                className="onoffswitch2"
                                onClick={this.onClickWalletActivity}
                                >
                                <input 
                                    type="radio" 
                                    className="onoffswitch2-checkbox" 
                                    checked={this.state.walletActivity}
                                    />
                                <label for="myonoffswitch" className="onoffswitch2-label"></label>
                            </div>
                        </div>}


                        {functions.findInObject(accessInfo, "USER-CTRL-0005") &&
                        <div 
                            className="switch-toggle d-flex" 
                            >
                            <span className="mr-auto">
                                <i className="fas fa-chevron-circle-right primary"/>&nbsp;
                                Enable Wallet Withdraws
                            </span>
                            <div 
                                className="onoffswitch2"
                                onClick={this.onClickWalletWithdraws}
                                >
                                <input 
                                    type="radio" 
                                    className="onoffswitch2-checkbox" 
                                    checked={this.state.walletWithdraws}
                                    />
                                <label for="myonoffswitch" className="onoffswitch2-label"></label>
                            </div>
                        </div>}


                        {functions.findInObject(accessInfo, "USER-CTRL-0006") &&
                        <div 
                            className="switch-toggle d-flex" 
                            >
                            <span className="mr-auto">
                                <i className="fas fa-chevron-circle-right primary"/>&nbsp;
                                Enable Account Logout
                            </span>
                            <div 
                                className="onoffswitch2"
                                onClick={this.onClickEnableLogout}
                                >
                                <input 
                                    type="radio" 
                                    className="onoffswitch2-checkbox" 
                                    checked={this.state.exitButton}
                                    />
                                <label for="myonoffswitch" className="onoffswitch2-label"></label>
                            </div>
                        </div>}
                

                </div>
            </div>




        </div>
    </div>}
    </>

      );
    
 
  }
}

export default UserControls