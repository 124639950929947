import React from 'react';
import ajax from '../../ajax';
import functions from '../../functions';
import DashCard from '../Common/DashCard';


class CustomerSummary extends React.Component {
  
  state={
    userId:functions.sessionGuard(),
    customerInfo:this.props.info
  }


    
  render(){
    
      return (
        
        <div className="text-wrap">
        <div className="example bg-white">
            <div className="btn-list">
                <button type="button" className="btn ripple btn-primary btn-sm">
                    Loan Limit 
                    <span className="badge badge-light ml-2">{this.state.customerInfo.loan_worth.amount_c}</span>
                </button>
                <button type="button" className="btn ripple btn-secondary btn-sm">
                    Total Loans <span className="badge badge-dark ml-2">{this.state.customerInfo.loans.total_loan.amount_c}</span>
                </button>
                <button type="button" className="btn ripple btn-success btn-sm">
                    Total Paid <span className="badge badge-light ml-2">{this.state.customerInfo.loans.total_paid.amount_c}</span>
                </button>
              
    
            </div>
        </div>
        </div>
          
      );
    
 
  }
}

export default CustomerSummary;